import React from 'react';
import { Head, Link } from '@inertiajs/react';
import GuestLayout from '@/components/v2/layouts/GuestLayout.jsx';
import IconButton from '@/components/v2/IconButton.jsx';
import { ReactComponent as ArrowLeftIcon } from '@assets/icons/arrow-left.svg';

const Error = ({ status }) => {
    const title = {
        503: 'Service tijdelijk niet beschikbaar',
        500: 'Er is een probleem opgetreden',
        404: 'Pagina niet gevonden',
        403: 'Geen toegang tot deze pagina',
    }[status];

    const description = {
        503: 'Onze service is tijdelijk niet beschikbaar vanwege onderhoud. Probeer het later opnieuw.',
        500: 'Er is een probleem opgetreden aan onze kant. Probeer het later opnieuw, of neem contact met ons op voor ondersteuning.',
        404: 'De door u opgevraagde pagina is niet beschikbaar of mogelijk verplaatst. Controleer of het webadres correct is ingevoerd.',
        403: 'U heeft momenteel geen toegang tot deze pagina. Neem contact op met uw accountbeheerder als u denkt dat dit een vergissing is.',
    }[status];

    return (
        <>
            <Head title={title} />

            <div className='flex grow flex-col justify-between gap-10'>
                <div />

                <div className={'flex flex-col gap-10'}>
                    <Link href={route('index')} className='flex items-center gap-2'>
                        <IconButton className={'size-10 border border-soft-grey bg-transparent text-deep-black hover:bg-deep-black hover:text-pure-white'}>
                            <ArrowLeftIcon className={'size-5'} />
                        </IconButton>
                        <span className='font-semibold'>Terug naar de startpagina</span>
                    </Link>

                    <div className='flex flex-col gap-5'>
                        <h1 className='text-5xl font-semibold'>{title}</h1>
                        <p>{description}</p>
                    </div>
                </div>

                <div />
            </div>
        </>
    );
};

Error.layout = (children) => <GuestLayout children={children} />;

export default Error;
